/* eslint-disable no-unused-vars */
<template>
  <div>
    <div v-if="presignedURLs" class="container">
      <div>
        <button
          class="lightest-grey-button min-width-button sharp-corner-button"
          @click="$router.push({ name: 'application-page' })"
        >
          <span class="back-button"> <img src="~/assets/img/back_button-black.svg"> {{ getTexts('backToApplications') }} </span>
        </button>
      </div>
      <div class="header" style="margin-top: 16px">
        <h1>{{ presignedURLs.length > 1 ? getTexts('uploadedDocuments') : getTexts('uploadedDocument') }}</h1>
        <h2>{{ `${presignedURLs.length}` }} {{ presignedURLs.length > 1 ? getTexts('items') : getTexts('item') }} </h2>
        <br>
        <div class="subheader">
          <p> {{ getTexts('customer') }} <span style="font-weight:bold">{{ customer.firstName }} ({{ customer[appIdentifierField] }})</span> </p>
          <div v-if="viewableFiles.length">
            <button class="lightest-grey-button min-width-button sharp-corner-button" @click="openAllFiles()">
              <span>{{ getTexts('openAllFiles') }}</span>
              <img id="view-all-icon" src="../../assets/logos/external-link.svg" width="12" height="12">
            </button>
            <button class="lightest-grey-button min-width-button sharp-corner-button" @click="downloadController()">
              <span>{{ getTexts('downloadAll') }}</span>
              <img
                v-if="!downloadInProgress"
                id="download-icon"
                src="../../assets/logos/download-solid.svg"
                width="12"
                height="12"
              >
              <img
                v-else
                id="spinner-icon"
                src="../../assets/logos/spinner-solid.svg"
                width="12"
                height="12"
              >
            </button>
          </div>
        </div>
      </div>

      <div v-for="(section, name, index) of sortedFiles" :key="name" class="section-div">
        <div v-if="section.length > 0" class="section-header">
          {{ index + 1 }}. {{ getFileTypeLabel(name) }} -
          <span style="text-transform: initial;">
            {{ section.length }}
            {{ ((section.length) > 1) ? getTexts('files') : getTexts('file') }}
          </span>
        </div>

        <div v-for="file of section" :key="file.name" class="file-div" :class="file.isDeleted ? 'document-disabled' : ''">
          <template v-if="!file.isDeleted && !isRedactingUpload(name, file.key)">
            <div class="file-item">
              <a :href="file.URL" target="_blank" :download="file.name">
                <div class="file-label-icon">
                  <img :src="getImg(file.extension)">
                  {{ file.linkedTo ? file.linkedTo.fileName : file.name.replace(/\[(.*?)\]/, '') }} <span v-if="file.isRedacting">(Loading)</span>
                </div>
              </a>
            </div>
            <div class="file-button">
              <img id="file-icon" class="file-float-img" src="../../assets/logos/download-solid.svg" @click="downloadFile(file)">
            </div>
            <div class="file-upload-time">
              {{ getTexts('uploadedOn') }} {{ formatTime(file.lastModified) }}
            </div>
          </template>
          <template v-else-if="isRedactingUpload(name, file.key)">
            <div class="file-item">
              <div class="file-label-icon">
                <img :src="getImg(file.extension)">
                {{ file.name.replace(/\[(.*?)\]/, '') }} (loading)
              </div>
            </div>
            <div class="file-button button-disabled">
              <img id="file-icon" class="file-float-img" src="../../assets/logos/download-solid.svg">
            </div>
            <div class="file-upload-time">
              {{ miscTexts.uploadedOnLabel }} {{ formatTime(file.lastModified) }}
            </div>
          </template>
          <template v-else>
            <div class="file-item">
              <div class="file-label-icon">
                <img :src="getImg(file.extension)">
                {{ file.name.replace(/\[(.*?)\]/, '') }} (deleted)
              </div>
            </div>
            <div class="file-button button-disabled">
              <img id="file-icon" class="file-float-img" src="../../assets/logos/download-solid.svg">
            </div>
            <div class="file-upload-time">
              {{ miscTexts.uploadedOnLabel }} {{ formatTime(file.lastModified) }}
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-else-if="isLoading" class="container spinner-container">
      Loading...
      <i class="loader" />
    </div>

    <div v-else-if="error.status" class="container error-container">
      <h1>Oops, broken link!</h1>
      <span v-if="error.message">
        <i> {{ `Error Message: ${error.message}` }} </i>
        <br><br>
      </span>
      Please confirm that you're using the right link. If the issue persists, please contact your Lendela rep for further assistance. <br>
    </div>

    <div v-else class="container transparent-container">
      Lendela Drive
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import application from '../../mixins/apiServices/application';

import { useAppStore } from '../../store/app';
import { useNuxtStore } from '../../store/nuxt';
import { useDomainStore } from '../../store/domain';

export default {
  mixins: [application],
  data () {
    return {
      presignedURLs: [

      ],
      customer: {
        firstName: '',
        personalNumber: '',
        _id: '',
      },
      sortedFiles: {},
      isLoading: true,
      downloadInProgress: false,
      error: {
        status: false,
        message: '',
      },
      appId: '',
      fileTypeLabels: [],
      appIdentifierField: 'personalNumber',
      appStore: useAppStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },
  computed: {
    currentProduct () {
      return this.appStore.currentProduct;
    },

    viewableFiles () {
      return this.presignedURLs.filter(file => file.URL).filter(file => !file.isRedacting);
    },
  
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    uploadDocuments () {
      return this.domainStore.uploadDocuments();
    },

    languageIsEnglish () {
      return this.nuxtStore.language === 'EN';
    },

    downloadAllLabel () {
      const { downloadAll } = this.miscTexts;

      if (downloadAll && downloadAll.label) return downloadAll.label;

      return 'Download All';
    }
  },

  mounted () {
    this.appId = decodeURIComponent(this.$route.query.id);

    this.retrieveFiles(this.appId, this.currentProduct)
      .then((response) => {
        if (response.responseCode !== 200) {
          this.handleError(response);
        }

        this.isLoading = false;
        const { data } = response;
        this.presignedURLs = data.presignedURLs;
        this.customer = data.customer;
        this.sortFiles(data.presignedURLs);
        this.fileTypeLabels = new Set(this.fileTypeLabels);

        if (data.appIdentifierField) {
          this.appIdentifierField = data.appIdentifierField;
        }
      }).catch((error) => {
        this.isLoading = false;
        console.warn(`${error}`)
      })
  },
  methods: {
    sortFiles (files) {
      const miscFiles = [];

      files.forEach((file) => {
        const fileType = file.category;

        // if misc file type, push to separate list
        if (!fileType) return miscFiles.push(file);

        if (!this.sortedFiles[fileType]) this.sortedFiles[fileType] = [];
        this.sortedFiles[fileType].push(file);
      });

      this.sortedFiles.misc = miscFiles; // separated so that item is last in the list
    },
    openAllFiles () {
      this.viewableFiles.forEach((file) => {
        window.open(file.URL, '_blank');
      });
    },
    async downloadController () {
      this.downloadInProgress = true;
      if (this.viewableFiles.length > 1) await this.downloadFiles();
      else await this.downloadFile();
    },
    async downloadFile (file) {
      this.downloadInProgress = true;
      const { URL, category, extension } = file || this.viewableFiles[0];
      const fileName = `${this.customer[this.appIdentifierField]}-${category}.${extension}`;

      const response = await axios({
        url: URL,
        method: 'GET',
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      this.downloadInProgress = false;
    },
    async downloadFiles () {
      this.downloadInProgress = true
      const zip = new JSZip();
      let count = 0;
      const zipFileName = `${this.customer[this.appIdentifierField]}.zip`;

      await Promise.all(this.viewableFiles.map(async (file) => {
        try {
          const response = await axios({
            url: file.URL,
            method: 'GET',
            responseType: 'blob',
          });

          zip.file(`[${file.category}] ${file.name}.${file.extension}`, response.data, { binary: true });
          count += 1

          if (count === this.viewableFiles.length) {
            const content = await zip.generateAsync({ type: 'blob' });

            FileSaver.saveAs(content, zipFileName);
          }
        } catch (error) {
          console.error(error);
        }
      }))

      this.downloadInProgress = false;
    },
    fileType (filename) {
      const splitName = filename.split('.');
      const type = splitName[splitName.length - 1];

      return type;
    },
    getImg(type) {
      try {
        return new URL(`../../assets/files/${String(type).toLowerCase()}.svg`, import.meta.url).href;
      } catch (e) {
        return new URL('../../assets/files/file.svg', import.meta.url).href;
      }
    },
    handleError (e) {
      this.isLoading = false;
      this.error.status = true;
      if (e) {
        this.error.message = e.data;
      }
    },
    isRedactingUpload (section, fileKey) {
      const uploaedDoc = this.sortedFiles[section].find(upload => upload.key === fileKey && upload.isRedacting);

      if (uploaedDoc) {
        return true;
      }

      return false;
    },
    formatTime (time) {
      return moment(time).format('LLL');
    },
    getFileTypeLabel (fileType) {
      if (!fileType) return;

      const translatedLabels = this.uploadDocuments.find(translatedLabel => translatedLabel.value === fileType);

      if (translatedLabels !== undefined) return translatedLabels.text;

      return fileType.toUpperCase();
    },
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
  },
}
</script>

<style>
  .back-button > img {
    height: 10px;
    margin-right: 8px;
  }
  .file-upload-time {
    margin-left: 40px
  }
</style>
